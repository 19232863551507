/** @format */

import { app } from '@3deye-toolkit/core';
import Camera, {
	Control,
	Controls,
	PlayerContext,
} from '@3deye-toolkit/react-camera';
import '@3deye-toolkit/react-camera/dist/react-camera.css';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import './styles.css';
import rockset from '@rockset/client';

let userEmail = document.cookie.match('cf-access-authenticated-user-email=([^;]*)')[1];

if (userEmail === null) {
  userEmail = "unauthorized@nope.com";
}

const apikey =
	'2MMFpTmaSKeovClnVSGqkAcvtKdB6Yn6nSpawnbt9Bg4xwM1bEpaV6i3rHgocwQx';
const apiserver = 'https://api.use1a1.rockset.com';
const rocksetClient = rockset(apikey, apiserver);
let result;
await rocksetClient.queryLambdas
	.executeQueryLambdaByTag('production', 'getEvent', 'latest', {
    /* (optional) list of parameters that may be used in the query, that overwrite default parameters */
    parameters: [{ name: "email", type: "string", value: userEmail }],
  })
	.then((json) => {
		result = json.results[0];
	})
	.catch(console.log);

function openWindow(url) {
	window.open(url, '_blank');
}

function doAck(ackType) {
	window.alert(ackType + ' acknowledged');
}

let dialog = document.getElementById('eventThumbnailDialog');

function openDialog() {
	dialog.showModal();
}
function closeDialog() {
	dialog.close();
}

let token = result.token;

app.init({ token });

export default function App() {
	return (
		<div className='App'>
			<div className='header'>
				<span>
					<button
						className='goTo'
						target='_blank'
						onClick={() => openWindow(result.incidentUrl)}
					>
						Open Incident
					</button>
				</span>
				<span className='siteName'>{result.siteName}</span>
				<span className='cameraName'>{result.cameraName}</span>
				<span>
					<button
						className='goTo'
						target='_blank'
						onClick={() => openWindow(result.vmsAlarmUrl)}
					>
						Open VMS
					</button>
				</span>
			</div>
			<div className='viewBox'>
				<Camera
					cameraId={result.vmsCameraId}
					controls={<CustomControls />}
					style={{ width: '100%', aspectRatio: '16/9' }}
				/>
			</div>
			<div className='primaryButtonRow'>
				<button
					className='ack'
					id='Normal'
					aria-label='Normal'
					target='_blank'
					onClick={() => doAck('Normal')}
				>
					Normal
				</button>
				<button
					className='ack'
					id='Suspicious'
					aria-label='Suspicious'
					target='_blank'
					onClick={() => doAck('Suspicious')}
				>
					Suspicious
				</button>
				<button
					className='ack'
					id='Loitering'
					aria-label='Loitering'
					target='_blank'
					onClick={() => doAck('Loitering')}
				>
					Loitering
				</button>
				<button
					className='ack'
					id='Tresspassing'
					aria-label='Tresspassing'
					target='_blank'
					onClick={() => doAck('Tresspassing')}
				>
					Tresspassing
				</button>
				<button
					className='ack'
					id='Theft'
					aria-label='Theft'
					target='_blank'
					onClick={() => doAck('Theft')}
				>
					Theft
				</button>
				<button
					className='ack'
					id='Emergency'
					aria-label='Emergency'
					target='_blank'
					onClick={() => doAck('Emergency')}
				>
					Emergency
				</button>
			</div>
			<div className='siteDetailsContainer'>
				<div className='siteDetailsColumn'>
					<div className='siteDetailsRow'>
						<h5>Site Notes:</h5>
						<p>{result.siteNotes}</p>
					</div>
				</div>

				<dialog
					id='eventThumbnailDialog'
					className='eventThumbnailDialog'
				>
					<img
						onClick={() => closeDialog()}
						className='eventThumbnailLarge'
						src={result.thumbnailUrl}
						alt='placeholder'
					/>
				</dialog>

				<div className='eventThumbnailColumn'>
					<img
						onClick={() => openDialog()}
						className='eventThumbnail'
						src={result.thumbnailUrl}
						alt='placeholder'
					/>
				</div>
				<div className='siteDetailsColumn'>
					<div className='siteDetailsRow'>
						<h5>Talkdown Instructions:</h5>
						<p>URL: {result.talkdownInstructions.url}</p>
						<p>Phone: {result.talkdownInstructions.phone}</p>
						<p>Note: {result.talkdownInstructions.note}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

const CustomControls = observer(() => {
	const player = useContext(PlayerContext);
	return (
		<Controls>
			<Control
				style={{ width: '100%' }}
				onClick={player.toggleResizeMode}
			>
				{player.videoResizeMode ? 'contain' : 'fill'}
			</Control>
			<Control
				style={{ width: '100%' }}
				onClick={player.toggleMute}
			>
				{player.muted ? 'unmute' : 'mute'}
			</Control>
			<Control
				style={{ width: '100%' }}
				onClick={player.togglePlayback}
			>
				{player.paused ? 'play' : 'pause'}
			</Control>
		</Controls>
	);
});
